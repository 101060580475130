* {
  box-sizing: border-box;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

div[role='dialog'] {
  /* Do not allow MS task modal horizontal scroll */
  overflow: hidden !important;
}

body {
  margin: 5em;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

.settings_container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-height: 100vh;
  background-color: rgb(245, 245, 245);
  padding-bottom: 40px;
}

.avatar_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 100vh;
  background-color: rgb(245, 245, 245);
}

input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid #484644;
  cursor: pointer;
}

.contrast_radio input[type='radio']:checked:after {
  background-color: #ffffff;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: white;
  border: 4px solid #6264a7;
  content: '';
  display: inline-block;
  visibility: visible;
  cursor: pointer;
}

.dark_radio input[type='radio']:checked:after {
  background-color: #292929;
  border: 4px solid #6264a7;
}

.contrast_radio input[type='radio']:checked:after {
  background-color: #292929;
  border: 4px solid #ffff54;
}

.contrast_radio input[type='radio']:checked + label {
  font-weight: 600;
}

.default_radio input[type='radio']:checked + label {
  font-weight: 600;
}

.dark_radio input[type='radio']:checked + label {
  font-weight: 600;
}

input[type='radio']:focus {
  outline: none;
  box-shadow: none;
}

.section_title {
  /* The title header style of each section e.g. Personal profile name, People you collaborate... etc. */
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  margin-bottom: 2px;
}

.settings_section_title {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  margin-bottom: 12px;
}

/* Placeholders */

.side_panel_placeholder_default_background {
  background-color: #f5f5f5;
}

.side_panel_placeholder_dark_background {
  background-color: #1f1f1f;
}

.side_panel_placeholder_contrast_background {
  background-color: #000000;
}

.placeholder_default_background {
  background-color: #ffffff;
}

.placeholder_dark_background {
  background-color: #292929;
}

.placeholder_contrast_background {
  background-color: #000000;
}

.settings_section {
  margin-bottom: 10px;
}

.section_shadow {
  box-shadow:
    0px 2px 4px rgba(0, 0, 0, 0.14),
    0px 0px 2px rgba(0, 0, 0, 0.12);
}

.summary_section_shadow {
  box-shadow:
    0px 2px 4px rgba(0, 0, 0, 0.14),
    0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.chevron_up {
  transform: rotate(180deg);
}

.chartContainer {
  width: 100%;
}

.dark_text {
  color: #242424;
}

.custom-check {
  accent-color: red;
}

input[type='checkbox'] {
  accent-color: #6264a7;
  cursor: pointer;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  position: relative;
  content: '';
  display: inline-block;
  visibility: visible;
}

.checkbox-container {
  display: flex;
  align-items: center;
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.checkbox-label {
  margin-left: 10px;
  margin-bottom: 5px;
}

input[type='checkbox']:focus {
  outline: none;
  box-shadow: none;
}

.sub_meeting_default {
  color: #242424;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
}
.sub_meeting_contrast,
.sub_meeting_dark {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
}

.sub_default_title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  color: #242424;
}

.sub_dark_title,
.sub_contrast_title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  color: #ffffff;
}

.default_sub_title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #616161;
}

.dark_sub_title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #cccccc;
}

.contrast_sub_title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.meeting_prominent-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-left: 6px;
}

.meeting_prominent-sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  margin-left: 6px;
}

.bg_default_secondary {
  background-color: #ffffff;
}

.bg_dark_secondary {
  background-color: #1f1f1f;
}

.bg_contrast_secondary {
  background-color: #000000;
}

/* Background colour for entire tab screens */
.bg_default_main {
  background-color: rgb(245, 245, 245);
}

.bg_dark_main {
  background-color: #141414;
}

.bg_contrast_main,
.bg_contrast_card {
  background-color: #000000;
}

/* Borders */

.border_default {
  border: none;
}

/* Personal dashboard */
.bg_default_card {
  background-color: white;
}

.bg_dark_card {
  background-color: #292929;
}

/* Energy row borders */

.border_default_row {
  border-bottom: 2px solid #ffffff;
}

.border_dark_row {
  border-bottom: 2px solid #1f1f1f;
}

/* text colours */
.text_colour_default {
  color: #242424;
}

.text_colour_dark,
.text_colour_contrast {
  color: #ffffff;
}

.comms_text {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
}

/* Meeting date text */

.meeting_default_date {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  color: #242424;
  margin-top: 4;
}

.meeting_dark_date,
.meeting_contrast_date {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  color: #ffffff;
}

.meeting_default_prominent_energy {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #616161;
}

.meeting_dark_prominent_energy {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #9a9a9a;
}

.meeting_contrast_prominent_energy {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
}

.insightsNoProfileTitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.insightsFlowTextProfileExists {
  color: #3c3c3c;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  word-wrap: break-word;
}

.insightsFlowText {
  color: #3c3c3c;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
}

.insightsFlowTextBold {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
}

.insightsFlowDropdownText {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #1673a3;
}

.insightsButton {
  background: #1673a3;
  background-image: 'BlueSwish.svg';
  border: 2px solid #1673a3;
  border-radius: 4px;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
}

.meetingButton {
  border-radius: 5px;
  color: #ffffff;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  padding: 7px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.4s;
}

.disabled {
  cursor: not-allowed;
}

.meetingButton_default {
  background-color: #5c5fc0;
  color: #ffffff;
}

.meetingButton_dark {
  background-color: #7f85f5;
  color: #000000;
}

.meetingButton_contrast {
  background-color: #ffff54;
  color: #000000;
}

.meetingButton_default:hover {
  background-color: #3d3e74;
  text-decoration: underline;
}

.meetingButton_dark:hover {
  background-color: #afb3f8;
  text-decoration: underline;
}

.meetingButton_contrast:hover {
  text-decoration: underline;
}

.meetingNoLinkButton {
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  padding: 7px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.4s;
}

.meeting_default_no_link_button {
  border: 1px solid #aeacac;
  color: #242424;
  background-color: #ffffff;
}

.meeting_dark_no_link_button {
  border: 1px solid #505050;
  color: #ffffff;
  background-color: #1f1f1f;
}

.meeting_contrast_no_link_button {
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: #000000;
}

.meeting_default_no_link_button:hover {
  background-color: #cccccc;
}

.meeting_dark_no_link_button:hover {
  background-color: #a5a5a5;
}

.insightsButton:hover {
  background: #2b7faa;
}

p {
  color: black;
}

.insights {
  min-width: 100vw;
  min-height: 100vh;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 3%;
  max-width: 100%;
  overflow-x: hidden;
}

.tab_config_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0px;
}

.tab_config_title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
  margin-left: 10px;
}

.tab_config_description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #252423;
  flex: 1;
  height: 100%;
  min-height: 100vh;
  margin-right: 10px;
  margin-left: 10px;
}

.communication_do_dont_container {
  /* min-height: 734px; */
}

.comms_do_dont_default_card {
  background: #ffffff;
  box-shadow:
    0px 2px 4px rgba(0, 0, 0, 0.14),
    0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.comms_do_dont_dark_card {
  background: #292929;
  box-shadow:
    0px 2px 4px rgba(0, 0, 0, 0.14),
    0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.comms_do_dont_contrast_card {
  background: #000000;
  border-radius: 4px;
}

/* Used to always show the increment/decrement buttons on number inputs - works on chrome, shown by default on other browsers */
input[type='number']::-webkit-inner-spin-button {
  opacity: 1;
}

.api_content_heading_default {
  white-space: nowrap;
  border-radius: 4px;
  margin-bottom: 6px;
  padding: 4px 8px;
  width: fit-content;
  text-align: center;
}

.faq_button {
  height: 48px;
  white-space: nowrap;
  border-radius: 4px;
  margin-bottom: 6px;
  padding: 10px 20px 10px 20px;
  width: fit-content;
  text-align: center;
}

.card_padding {
  padding: 20px;
}

.summary_section {
  overflow-y: auto;
}

.no_setup_profile {
  min-height: 310px;
  overflow-y: auto;
}

.dropdownSection {
  border-bottom: 2px solid #c8cacd;
  padding-bottom: 4px;
  cursor: pointer;
  margin-bottom: 10px;
}

.dropdownSectionAbove {
  border-bottom: 2px solid #c8cacd;
  padding-bottom: 3px;
  cursor: pointer;
}

.view_colour_energies {
  background-color: #464eb8;
  color: #ffffff;
  border: none;
}

.colour_energy {
  width: 230px;
  padding: 8px;
  padding-left: 8px;
}

.energy_row_above_line {
  border-width: 0px;
  border-top-width: 1px;
  border-style: solid;
  margin-top: 5px;
  margin-bottom: 5px;
}

.energy_row_above_line_default {
  border-top-color: #616161;
}

.energy_row_above_line_dark {
  border-top-color: #cccccc;
}

.energy_row_above_line_contrast {
  border-top-color: #ffffff;
}

.colour_energy_rounded_top {
  border-radius: 4px 4px 0px 0px;
}

.colour_energy_rounded_bottom {
  border-radius: 0px 0px 4px 4px;
}

/* Person card */
.insights_person-card-container {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  padding-top: 15px;
}

.insights_default_card {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 0px 12px 12px 12px;
}

.insights_dark_card {
  background: #292929;
  border: 1px solid #4a4a4a;
  border-radius: 4px;
  padding: 0px 12px 12px 12px;
}

.insights_contrast_card {
  border: 1px solid #ffffff;
  border-radius: 4px;
  padding: 0px 12px 12px 12px;
}

.insights_card_outer {
  min-width: 200px;
}

/* Meeting card */
.meetings_default_card {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  min-height: 100%;
}

.meetings_dark_card {
  background: #1f1f1f;
  border: 1px solid #4a4a4a;
  border-radius: 4px;
  min-height: 100%;
}

.meetings_contrast_card {
  border: 1px solid #505050;
  border-radius: 4px;
  min-height: 100%;
}

/* Fiery Red */

.fieryRedEnergy_default {
  background-color: #db2f41;
  color: #ffffff;
}

.fieryRedEnergy_dark {
  background-color: #ff4c49;
  color: #000000;
}

.fieryRedEnergy_contrast {
  border: solid 1px #ffffff;
  color: #ffffff;
}

/* Sunshine Yellow */

.sunshineYellowEnergy_default {
  background-color: #ffd74b;
  color: #242424;
}

.sunshineYellowEnergy_dark {
  background-color: #ffc43a;
  color: #000000;
}

.sunshineYellowEnergy_contrast {
  border: solid 1px #ffffff;
  color: #ffffff;
}

/* Earth Green */

.earthGreenEnergy_default {
  background-color: #3b854b;
  color: #ffffff;
}

.earthGreenEnergy_dark {
  background-color: #5eb408;
  color: #000000;
}

.earthGreenEnergy_contrast {
  border: solid 1px #ffffff;
  color: #ffffff;
}

/* Cool Blue */

.coolBlueEnergy_default {
  background-color: #0071bb;
  color: #ffffff;
}

.coolBlueEnergy_dark {
  background-color: #0099ff;
  color: #000000;
}

.coolBlueEnergy_contrast {
  border: solid 1px #ffffff;
  color: #ffffff;
}

.energy_input_hl {
  border-style: solid;
  border-width: 3px;
  border-radius: 3px;
  opacity: 1;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
}

.checkbox-wrapper input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  text-align: center;
  width: 16px;
  height: 16px;
  border-radius: 0.25em;
  margin-right: 1px;
  border: 0.15em solid #605e5c;
}

.checkbox-wrapper-insights-flow input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  text-align: center;
  min-width: 24px;
  min-height: 24px;
  border-radius: 4px;
  margin-right: 12px;
  margin-top: 5px;
  border: 0.12em solid #1673a3;
}

.checkbox-wrapper-contrast input[type='checkbox'] {
  border: 0.12em solid white;
}

.checkbox-wrapper-dark input[type='checkbox'] {
  border: 0.12em solid white;
}

.checkbox-wrapper-contrast input:checked {
  background-color: #feff44;
  border: 0.12em solid #feff44;
  position: relative;
}

.checkbox-wrapper-default input:checked {
  background-color: #6264a7;
  border: 0.12em solid #6264a7;
  position: relative;
}

.checkbox-wrapper-default input:checked + label {
  font-weight: 600;
}

.checkbox-wrapper-dark input:checked + label {
  font-weight: 600;
}

.checkbox-wrapper-contrast input:checked + label {
  font-weight: 600;
}

.checkbox-wrapper-dark input:checked {
  background-color: #6264a7;
  border: 0.12em solid #6264a7;
  position: relative;
}

input:checked {
  background-color: #6264a7;
  position: relative;
}

.checkbox-wrapper-insights-flow input:checked {
  background-color: rgb(22, 115, 163);
  position: relative;
}

.checkbox-wrapper-default input:checked::before {
  color: #ffffff;
}

.checkbox-wrapper-default input[type='checkbox']:checked::before {
  color: #ffffff;
}

.checkbox-wrapper-dark input:checked::before {
  color: #000000;
}

.checkbox-wrapper-dark input[type='checkbox']:checked::before {
  color: #000000;
}

.checkbox-wrapper-contrast input:checked::before {
  color: #000000;
}

.checkbox-wrapper-contrast input[type='checkbox']:checked::before {
  color: #000000;
}

input[type='checkbox']:checked::before {
  margin: auto;
  position: absolute;
  top: -4px;
  left: 0;
  bottom: 0px;
  right: 0;
  content: '\2714\fe0e';
  font-size: 10px;
  color: #fff;
}

.checkbox-wrapper-insights-flow input:checked::before {
  right: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  content: '\2714';
  font-size: 1em;
  color: #fff;
  position: absolute;
}

.settings_notification_subtext {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
}

.settings_notification_disclaimer {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  max-width: 640px;
}

/* Modified from a sample for toggles by Mark Uretsky */
.switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 20px;
  outline: none;
}

.switch input {
  position: absolute;
  width: 0;
  height: 0;
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 3px;
  border: 1px solid #605e5c;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  border: 1px solid white;
  top: 1px;
  left: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.slider_dark:before {
  border: 1px solid white;
  background-color: #242424;
}

.slider_contrast:before {
  border: 1px solid white;
  background-color: #000000;
}

.slider_default:before {
  border: 1px solid #605e5c;
  background-color: white;
}

.slider_default {
  background-color: rgb(245, 245, 245);
}

input:checked + .slider_contrast {
  background-color: #5fe3fb;
}

input:focus + .slider_contrast {
  box-shadow: 0 0 1px #0d6c51;
}

input:checked + .slider_default {
  background-color: #6264a7;
}

input:focus + .slider_default {
  box-shadow: 0 0 1px #6264a7;
}

input:checked + .slider_dark {
  background-color: #6264a7;
  border: 1px solid #6264a7;
}

input:focus + .slider_dark {
  box-shadow: 0 0 1px #6264a7;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

input:checked + .slider_dark:before {
  border: 1px solid #242424;
}

input:checked + .slider_contrast:before {
  border: 1px solid #242424;
}

/* END */

.color-wheel {
  --diameter: 130px;
  --stroke-width: 18.75px;
  margin-bottom: 15px;
  position: relative;
  width: var(--diameter);
  height: var(--diameter);
}

.color-wheel > .color-circle {
  position: relative;
  left: 0;
  top: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: conic-gradient(
    #45aef9 0%,
    #3d7b96 12.5%,
    #3b854b 25%,
    #b3cf43 37.5%,
    #ffd84b 50%,
    #fb9649 62.5%,
    #d13a4a 75%,
    #7866bf 87.5%,
    #45aef9 100%
  );
}

.color-wheel > .inner-circle {
  border-radius: 50%;
  background: white;
}

.color-wheel > .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.noProfileSetInfoSection {
  display: flex;
  flex-direction: column;
  padding-left: 12px;
}

.noProfileSetInfoSectionTitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  width: 75%;
  margin-bottom: 0px;
}

.noProfileSetInfoSectionContainer,
.profileSetInfoSectionContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
  margin-top: 16px;
  padding-right: 5px;
}

.noProfileSetInfoItemContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 260px;
}

.profileSetInfoItemContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 340px;
}

.noProfileSetInfoItemTitle,
.profileSetInfoItemTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 12px;
}

.iconImage {
  width: 32px;
  height: 32px;
}

.noProfileInfoSetSpacer {
  border-top: solid 1px #cccccc;
  width: 100px;
  margin-top: 32px;
  margin-bottom: 32px;
}

.noProfileSetInfoSectionFooter {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.secondaryButton {
  width: 138px;
  line-height: 32px;
  padding: 6px, 16px, 6px, 16px;
  border-radius: 4px;
  text-decoration: none !important;
  text-align: center;
}

.secondaryButton_default {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  color: #242424;
}

.secondaryButton_dark {
  background-color: #1f1f1f;
  border: 1px solid #7a7a7a;
  color: #ffffff;
}

.secondaryButton_contrast {
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #ffffff;
  margin-bottom: 10px;
}

.secondaryButton_default:hover {
  border: 2px solid #cccccc;
  color: #616161;
  text-decoration: none !important;
}

.secondaryButton_dark:hover {
  border: 2px solid #7a7a7a;
  color: #cccccc;
  text-decoration: none !important;
}

.secondaryButton_contrast:hover {
  border: 2px solid #ffffff;
  color: #ffffff;
  text-decoration: none !important;
}

.teamsButton {
  all: unset;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  padding: 6px 16px;
  border-radius: 4px;
}

.teamsButtondefault {
  background-color: #5c5fc0;
  color: #ffffff;
}

.teamsButtondefault:hover {
  background-color: #3d3e74;
  text-decoration: none !important;
}

.teamsButtondark {
  background-color: #7f85f5;
  color: #000000;
}

.teamsButtondark:hover {
  background-color: #afb3f8;
  text-decoration: none !important;
}

.teamsButtoncontrast {
  background-color: #ffff54;
  color: #000000;
}

.teamsButtonSecondarydefault {
  background-color: #ffffff;
  color: #242424;
  border: solid 1px #cccccc;
}

.teamsButtonSecondarydefault:hover {
  text-decoration: underline;
  color: #616161;
  border: solid 1px #cccccc;
}

.teamsButtonSecondarydark {
  background-color: #1f1f1f;
  color: #ffffff;
  border: solid 1px #7a7a7a;
}

.teamsButtonSecondarydark:hover {
  text-decoration: underline;
  color: #cccccc;
  border: solid 1px #7a7a7a;
}

.teamsButtonSecondarycontrast {
  background-color: #000000;
  color: #ffffff;
  border: solid 1px #ffffff;
}

.teamsButtonSecondarycontrast:hover {
  background-color: #000000;
  color: #ffffff;
  border: solid 1px #ffffff;
  text-decoration: underline;
}

.noProfileButtonContainer {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.colourEnergyBreakdownCard {
  width: 100%;
  border-radius: 4px;
  border: solid 1px #b3b3b3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  padding-bottom: 0px;
}

.colourEnergyBreakdownTitle {
  color: #3c3c3c;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  word-wrap: break-word;
  margin-bottom: 3px;
}

.colourEnergyBreakdownSubtitle {
  color: #3c3c3c;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  word-wrap: break-word;
  margin-bottom: 3px;
}

.colourEnergyBreakdownChapters {
  color: #6d6d6d;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
}

.colourEnergySplitContainer {
  display: flex;
  width: 100%;
}

.energyBlock {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.energyBlockText {
  font-size: 14;
  font-weight: 600;
  line-height: 24;
  word-wrap: break-word;
}

.energyBlockOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.percentageLabel {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
}

.percentageLabel_default {
  color: #616161;
}
.percentageLabel_dark {
  color: #cccccc;
}
.percentageLabel_contrast {
  color: #ffffff;
}

.exploreBorderLeft {
  border-radius: 4px 0px 0px 4px;
}

.exploreBorderRight {
  border-radius: 0px 4px 4px 0px;
}

.energyBlockBorderRight {
  border-right: solid 2px #ffffff;
}

.avatarLink {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
  text-decoration: none;
}

.avatarLink_default {
  color: #5c5fc0;
}

.avatarLink_dark {
  color: #7f85f5;
}

.avatarLink_contrast {
  color: #ffff54;
}

.avatarTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
}

.avatarTitle_default {
  color: #242424;
}

.avatarTitle_dark {
  color: #ffffff;
}

.avatarTitle_contrast {
  color: #ffffff;
}

.avatarParagraph {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
  text-align: center;
}

.avatarParagraph_default {
  color: #616161;
}
.avatarParagraph_dark {
  color: #cccccc;
}
.avatarParagraph_contrast {
  color: #ffffff;
}

.avatar-export-message-container {
  width: 70%;
  padding: 15px;
  margin-bottom: 25px;
  border-radius: 4px;
  background-color: rgb(91, 95, 198, 0.15);
}

.avatar-upload-error-message {
  width: 70%;
}

@media only screen and (max-width: 600px) {
  .avatar-export-message-container {
    width: 100%;
  }

  .avatar-upload-error-message {
    width: 100%;
  }
}

.avatar-export-message {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
  text-align: center;
  margin-bottom: 0px;
}

.avatar-export-message_contrast {
  color: #ffffff;
}

.avatar-export-message_dark {
  color: #ffffff;
}

.avatar-export-message_default {
  color: #242424;
}

.paragraphContainer {
  display: flex;
  flex-direction: column;
}

.donutContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.image-avatar {
  position: absolute;
  height: 350px;
  width: 350px;
  object-fit: cover;
  border-radius: 50%;
}

.uploadPhotoButttonContainer {
  cursor: pointer;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  height: 230px;
  width: 230px;
  border-radius: 50%;
}

.donutChart {
  border-radius: 50%;
  width: 350px;
  height: 350px;
  z-index: 99;
}

@media only screen and (max-width: 600px) {
  .donutChart {
    width: 250px;
    height: 250px;
  }

  .image-avatar {
    height: 250px;
    width: 250px;
  }
}

.uploadPhotoText {
  all: unset;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  word-wrap: break-word;
}

.useProfileText {
  all: unset;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
}

.personal_profile_title {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
  margin: 0px;
}

.personal_profile_text_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.personal_profile_subtext {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
}

.small_text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
}

.jumpToContainer {
  width: 100%;
  height: auto;
  min-height: 45px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.border_radius_rainbow {
  border-radius: 8px 8px 0px 0px;
}

.compare_border_top {
  border-style: solid;
  border-width: 0;
  border-top-width: 1px;
}

.profile_breakdown_scrollable_area {
  overflow-y: scroll;
  max-height: 271px;
}

.tableContentHeight {
  max-height: 280px;
}

.deeplinkMobileHelpText {
  display: none;
}

.hideDeeplinkButtonMobile {
  display: block;
}

.paddingLeft20 {
  padding-left: 20px;
}

.hideCustomise {
  display: block;
}

.selectBorder_default:hover {
  border: solid 1px #f9f9f9;
}

.selectBorder_dark:hover {
  border: solid 1px #4a4a4a;
}

.selectBorder_contrast:hover {
  border: solid 2px #ffffff;
}

@media only screen and (max-width: 600px) {
  .supportLink_container {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .deeplinkMobileHelpText {
    display: block;
  }

  .paddingLeft20 {
    padding-left: 0px;
  }

  .hideDeeplinkButtonMobile {
    display: none;
  }

  .summary_section {
    max-height: 600px;
  }

  .communication_do_dont_container {
    min-height: 790px;
  }

  .section_title {
    margin: 1px;
  }

  .border_radius_rainbow {
    border-radius: 8px 8px 8px 8px;
  }

  .compare_border_top {
    border: none;
    border-top: 0;
  }

  .profile_breakdown_scrollable_area {
    overflow-y: scroll;
  }

  .tableContentHeight {
    max-height: 300px;
  }

  .hideCustomise {
    display: none;
  }

  .insights_week_section {
    max-height: 200px;
  }

  .insights_default_card {
    height: 250px;
  }
}

@media only screen and (min-width: 768px) {
  .jumpToContainer {
    height: 45px;
  }
}

.jumpToContainer_default {
  background-color: rgba(92, 95, 192, 0.15);
}

.jumpToContainer_dark {
  background-color: rgba(42, 42, 59);
}

.jumpToContainer_contrast {
  background-color: #000000;
  border-top: solid 1px #ffffff;
  border-bottom: solid 1px #ffffff;
}

.jumpToLinks {
  text-decoration: none !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
  margin-left: 8px;
  cursor: pointer;
}

.box_border_default {
  border: solid 1px #616161;
}

.box_border_dark {
  border: solid 1px #cccccc;
}

.box_border_contrast {
  border: solid 1px #ffffff;
}

.highlight_container {
  display: flex;
  flex-direction: column;
}

.personal_dashboard_button {
  all: unset;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
}

.personal_profile_spacer {
  border-style: solid;
  border-width: 1px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 12px;
}

.personal_profile_spacer_default {
  border-color: #e6e6e6;
}
.personal_profile_spacer_dark {
  border-color: #4a4a4a;
}
.personal_profile_spacer_contrast {
  border-color: #ffffff;
}

.personal_profile_shadow {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}
.profile_breakdown_shadow_bottom {
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.15) !important;
}

.strengths_weakness_column {
  flex: 1;
}

.do_box_border_default {
  border: solid 1px #0d6c51;
  color: #0d6c51;
}

.do_box_border_dark {
  border: solid 1px #1bac83;
  color: #1bac83;
}

.do_box_border_contrast {
  border: solid 1px #6ee8fc;
  color: #6ee8fc;
}

.avatar_error_message_default {
  color: #93011b;
}

.avatar_error_message_dark {
  color: #ff1a44;
}

.avatar_error_message_contrast {
  color: #6ee8fc;
}

.dont_box_border_default {
  border: solid 1px #93011b;
  color: #93011b;
}

.dont_box_border_dark {
  border: solid 1px #ff1a44;
  color: #ff1a44;
}

.dont_box_border_contrast {
  border: solid 1px #6ee8fc;
  color: #6ee8fc;
}

.personal_profile_container_bg_default {
  background-color: rgba(0, 0, 0, 0.03);
}
.personal_profile_container_bg_dark {
  background-color: rgba(255, 255, 255, 0.02);
}
.personal_profile_container_bg_contrast {
  background-color: #000000;
}

/* START - My insights for this week */

.row-flex {
  display: flex;
  flex-wrap: wrap;
}

/* vertical spacing between columns */

.insights_weekly_padding {
  padding-bottom: 12px;
}

.insights_weekly_jumpToContainer {
  width: 100%;
  min-height: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 5px;
}

.insights_weekly_jumpToContainer {
  background-color: rgb(245, 245, 245);
}

.insights_week_section_content {
  height: 100%;
  padding: 20px 20px 10px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
}

.insights_week_section_content_default {
  background-color: white;
}

.insights_week_section_content_dark {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0);
  background: #1f1f1f;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
}

.insights_week_section_content_contrast {
  background-color: #000;
  border: 1px solid #fff;
}

.highlighted_default {
  background: #1673a3;
  color: #fff;
}
.highlighted_dark {
  background: #1fa0e0;
}

.insights_week_container_header {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
}

.insights_week_container_header_dark {
  color: white;
}

.insights_week_container_header_contrast {
  color: white;
}

.insights_week_container_sub_header {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  padding-right: 6px;
}

.insights_week_container_sub_header_dark {
  color: white;
}

.insights_week_container_sub_header_contrast {
  color: white;
}

.insights_week_section {
  max-height: 415px;
  overflow-y: auto;
  padding-right: 5px;
}

.insights_weekly_container_default {
  background: rgba(0, 0, 0, 0.03);
  box-shadow: -8px 11px 12px rgba(0, 0, 0, 0.03) inset;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.insights_weekly_container_dark {
  background: #191919;
  box-shadow: -8px 11px 12px rgba(0, 0, 0, 0.03) inset;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.insights_week_container_default {
  max-height: '100%';
  padding-left: 26px;
  padding-right: 20px;
}

.insights_week_container_dark {
  padding-left: 26px;
  padding-right: 20px;
  padding-bottom: 0px;
  max-height: '100%';
}

.insights_week_container_contrast {
  padding-left: 26px;
  padding-right: 20px;
  padding-bottom: 0px;
  max-height: '100%';
}

.insights_week_tile_line {
  width: 24px;
  height: 0;
  border: 0.5px solid #c4c4c4;
  margin: 3px;
  display: inline-block;
}

.insights_week_tile_line_default {
  border: 0.5px solid #616161;
}

.insights_week_tile_line_highlighted_default {
  border: 0.5px solid white;
}

.insights_week_tile_line_highlighted_dark {
  border: 0.5px solid #000;
}

.insights_week_tile_line_contrast {
  border: 0.5px solid white;
}

.insights_week_tile_title {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.insights_week_tile_title_default {
  color: #616161;
}

.insights_week_tile_title_dark {
  color: #ccc;
}

.insights_week_tile_title_contrast {
  color: white;
}

.insights_week_tile_desc {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.insights_week_tile_desc_default {
  color: #000;
}

.insights_week_tile_desc_dark {
  color: #fff;
}

.insights_week_tile_desc_contrast {
  color: white;
}

.insights_week_tile_title_highlighted_default {
  color: white;
}

.insights_week_tile_title_highlighted_dark {
  color: #000;
}

.insights_week_tile_title_highlighted_contrast {
  color: white;
}

.insights_week_section_title {
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  margin-right: 6px;
  text-decoration: none;
}

.insights_week_section_title_default {
  color: #5c5fc0;
}

.insights_week_section_title_dark {
  color: #5c5fc0;
}

.insights_week_section_title_contrast {
  color: #ffff54;
}

.insights_week_jumpToContainer_default {
  background-color: rgb(245, 245, 245);
}

.insights_week_jumpToContainer_dark {
  background-color: rgb(24, 24, 24);
}

.insights_week_jumpToContainer_contrast {
  background-color: rgb(0, 0, 0);
}

/* END - My insights for this week */

.dashboard-container {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.03);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
  border: solid 1px #ffffff;
}

.dashboard-container_contrast {
  border-radius: 4px;
  border: 1px solid white;
}

.dashboard-container_personal_profile {
  background-color: 'white';
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px;
  padding-right: 0px;
  padding-left: 0px;
}

.border-left_contrast {
  border-left: 1px solid white;
}

.border_contrast {
  border: 1px solid white;
}

.summaryCard-toggle-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 4px;
}

.summaryCard-toggle-text-selected {
  font-weight: 600;
}

.personal_profile_info_bg_default {
  background-color: #f9f9f9;
}

.personal_profile_info_bg_dark {
  background-color: #292929;
}

.rainbow_border_default {
  border: double 4px transparent;
  background-image: linear-gradient(#f9f9f9, #f9f9f9),
    radial-gradient(
      circle at top right,
      #0071bb 0%,
      #24959c 14%,
      #3b854b 30%,
      #b3cf43 44%,
      #ffd74b 58%,
      #fb9649 72%,
      #db2f41 86%,
      #7866bf 100%
    );
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #f9f9f9;
}

.rainbow_border_dark {
  border: double 4px transparent;
  background-image: linear-gradient(#292929, #292929),
    radial-gradient(
      circle at top right,
      #0071bb 0%,
      #24959c 14%,
      #3b854b 30%,
      #b3cf43 44%,
      #ffd74b 58%,
      #fb9649 72%,
      #db2f41 86%,
      #7866bf 100%
    );
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #292929;
}

.rainbow_border_contrast {
  border: 4px solid #ffffff;
  border-radius: 4px 4px 0px 0px;
  border-image-slice: 1;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.compare_profile_shadow {
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.05);
}

.preference_statement {
  margin-top: 12px;
  margin: 12px 18px 0px 18px;
  padding: 20px 16px 20px 20px;
}

.compare_profiles_statements_bg_default {
  background-color: #f9f9f9;
}

.compare_profiles_statements_bg_dark {
  background-color: #353535;
}

.compare_profiles_statements_bg_contrast {
  background-color: #000000;
}

.compare_profile_outer_border_default {
  border-color: #cccccc;
}

.compare_profile_outer_border_dark {
  border-color: #7a7a7a;
}

.compare_profile_outer_border_contrast {
  border-color: #ffffff;
}

.where_text {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
}

.meetings_error_adding_tab_default {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
  color: #a4262c;
}

.meetings_error_adding_tab_dark {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
  color: #ef6950;
}

.meetings_error_adding_tab_contrast {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
  color: #ef6950;
}

.scroll_contrast::-webkit-scrollbar {
  width: 24px;
}

.scroll_contrast::-webkit-scrollbar-thumb {
  border: 10px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: white;
  outline: 1px solid rgba(255, 255, 255, 0);
}

.scroll_contrast::-webkit-scrollbar-track {
  width: 14px;
}

.scroll_dark::-webkit-scrollbar {
  width: 24px;
}

.scroll_dark::-webkit-scrollbar-thumb {
  border: 10px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: rgba(255, 255, 255, 0.3);
  outline: 1px solid rgba(255, 255, 255, 0);
}

.scroll_dark::-webkit-scrollbar-track {
  width: 14px;
}

.scroll_default::-webkit-scrollbar {
  width: 24px;
}

.scroll_default::-webkit-scrollbar-thumb {
  border: 10px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: rgba(0, 0, 0, 0.3);
  outline: 1px solid rgba(0, 0, 0, 0);
}

.scroll_default::-webkit-scrollbar-track {
  width: 14px;
}

.statement {
  display: flex;
  flex-direction: column;
  padding: 12px 10px 12px 10px;
  border-radius: 4px;
  margin-right: 12px;
  margin-top: 9px;
}

.comms_list_container {
  max-height: 300;
  overflow-y: scroll;
}

.threePlusProfile {
  width: 24px;
  height: 24px;
  background: #5c5fc0;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -15px;
}

.threePlusProfileText {
  color: white;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
  z-index: 999;
  margin-right: 1px;
}

.insights_flow_border_colour_default {
  border-color: white;
}
.insights_flow_border_colour_dark {
  border-color: #292929;
}
.insights_flow_border_colour_contrast {
  border-color: #000000;
}

.sidePanel {
  display: flex;
  flex: 1;
}

.sidePanel_default {
  background-color: #f5f5f5;
}

.sidePanel_dark {
  background-color: #1f1f1f;
}

.sidePanel_contrast {
  background-color: #000000;
}

.side_panel_statement {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 12px 8px 12px 10px;
}

.side_panel_statement_border_default,
.side_panel_statement_border_dark {
  border: 1px solid rgba(74, 74, 74, 1);
  border-radius: 4px;
}

.side_panel_statement_border_contrast {
  border: 1px solid #ffffff;
  border-radius: 4px;
}

.select_attendee_text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word;
  margin-bottom: 3px;
}

.select_bg_dark,
.select_bg_default {
  background: rgba(41, 41, 41, 1);
}

.jump-to-table {
  width: 100%;
  text-align: left;
  position: relative;
  border-collapse: collapse;
}

.jump-to-table-header {
  background: white;
  position: sticky;
  bottom: 0px;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
}

/* Add some padding when scrolling to element ids*/
#personal_profile_comms,
#personal_profile_strengths_weaknesses,
#managementRef,
#effectiveRef,
#personalRef {
  scroll-margin-top: 60px;
}

.customise_section_title {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  word-wrap: break-word;
}

.customise_row_section_title {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
}

.error_text_default {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
  color: #a4262c;
  margin-bottom: 0px;
}

.error_text_dark {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
  color: #ef6950;
  margin-bottom: 0px;
}

.error_text_contrast {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
  color: #ef6950;
  margin-bottom: 0px;
}

.loaderText {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  position: absolute;
  margin-top: 215px;
  margin-left: 18px;
}

.loaderText_default {
  color: #616161;
}

.loaderText_dark {
  color: #cccccc;
}

.loaderText_dark {
  color: white;
}

.saveButton_fixed {
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  min-width: 100%;
  height: 52px;
  box-shadow: 0px -4px 6px 0px rgba(0, 0, 0, 0.08);
}

.saveButton_bg_default {
  background-color: #ffffff;
}

.saveButton_bg_dark {
  background-color: #292929;
}

.saveButton_bg_contrast {
  background-color: #000000;
  border-top: solid 1px #fff;
}

.supportLink_text {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 0px;
  opacity: 1;
}

.supportLink_bg_default {
  background-color: rgba(91, 95, 198, 0.15); /* #5b5fc6 */
}

.supportLink_bg_dark {
  background-color: rgb(127, 133, 245, 0.15); /* #7f85f5 */
}

.supportLink_bg_contrast {
  background-color: rgb(110, 232, 252, 0.15); /* #6ee8fc */
}

.supportLink_container {
  padding-right: 30px;
  padding-left: 16px;
  margin-top: 24px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: auto;
  min-height: 44px;
  gap: 0px;
  border-radius: 4px;
}
